import { getSubDomain } from '@guider-global/front-end-utils';
import { ILeaderboardPosition } from '@guider-global/shared-types';
import {
  IPaginationParams,
  IUseGuiderSWRParams,
  useGuiderSWR,
} from '@guider-global/swr';

interface IUseLeaderboardPositionsRequestParams extends IPaginationParams {}
interface UseLeaderboardPositionsParams
  extends Omit<IUseGuiderSWRParams<ILeaderboardPosition>, 'url'> {}

/**
 * @returns returns leaderboard positions
 */
export function useLeaderboardPositions({
  waitForAuthentication = true,
  params,
}: UseLeaderboardPositionsParams) {
  const organizationSlug = getSubDomain();
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<ILeaderboardPosition, IUseLeaderboardPositionsRequestParams>({
      waitForAuthentication,
      url: '/leaderboardPositions',
      params: {
        ...{ organizationSlug },
        ...params,
      },
      options: {
        keepPreviousData: true,
      },
    });

  return {
    leaderboardPositions: data,
    reqLeaderboardPositions: request,
    LeaderboardPositionsRevalidate: revalidate,
    errorsLeaderboardPositions: error,
    isLoadingLeaderboardPositions: isLoading,
    isErrorLeaderboardPositions: Boolean(error),
    isValidatingLeaderboardPositions: isValidating,
  };
}
