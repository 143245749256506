import { useLocation } from 'react-router-dom';
import {
  EProgramVariation,
  IProfile,
  IRelationship,
  IUser,
} from '@guider-global/shared-types';
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import { isSurveyFieldForGuide, isSurveyFieldForTrainee } from 'functions';
import { getSubDomain } from '@guider-global/front-end-utils';

export interface UseSurveyResourcesProps {
  surveyType: 'post_session_survey' | 'post_relationship_survey';
  relationship: IRelationship | undefined;
  user: IUser | undefined;
}

export function useSurveyResources({
  surveyType,
  relationship,
  user,
}: UseSurveyResourcesProps) {
  const { pathname } = useLocation();
  const isOnGuiderVideoPage = pathname.startsWith('/video');
  const organizationSlug = getSubDomain();
  const { programs } = useOrganizationPrograms({ organizationSlug });

  if (!relationship || !user) {
    return {};
  }

  const program = programs.find(
    (program) => program.metadata.id.current === relationship.programSlug,
  );
  const programName = program?.metadata.program_name;
  const surveys = program?.surveys;
  const survey = surveys?.[surveyType];
  const guideSingular =
    program?.program_details?.program_type?.program_type_text?.common?.guide
      ?.singular ?? 'Guide';
  const traineeSingular =
    program?.program_details?.program_type?.program_type_text?.common?.trainee
      ?.singular ?? 'Trainee';

  const isGroupProgram =
    relationship.programVariationTypeSlug === EProgramVariation.Group;

  const [guideProfile] =
    (relationship?.guideProfiles as Partial<IProfile>[]) ?? [];
  const [traineeProfile] =
    (relationship?.traineeProfiles as Partial<IProfile>[]) ?? [];
  const isGuide = guideProfile?.userId === user?.id;
  const guideName = guideProfile?.displayName;
  const traineeName = traineeProfile?.displayName;

  const name = isGuide ? traineeName : guideName;
  const role = isGuide ? traineeSingular : guideSingular;
  const title = survey?.title ?? '';
  const description = survey?.description ?? '';
  const heading = (isGroupProgram ? relationship.title : name) ?? '';
  const subheading =
    (isGroupProgram ? programName : `Your ${role} • ${programName}`) ?? '';

  const sessions = relationship?.sessions ?? [];
  const sessionCount = sessions.length;
  const surveyFields = survey?.fields ?? [];
  const guideFields = surveyFields.filter((surveyField) =>
    isSurveyFieldForGuide(surveyField, sessionCount),
  );
  const traineeFields = surveyFields.filter((surveyField) =>
    isSurveyFieldForTrainee(surveyField, sessionCount),
  );

  return {
    title,
    description,
    heading,
    subheading,
    profile: isGuide ? guideProfile : traineeProfile,
    surveyFields: isGuide ? guideFields : traineeFields,
    isGuide,
    isGroupProgram,
    isSurveyEnabled: survey?.enabled,
    isOnGuiderVideoPage,
  };
}
