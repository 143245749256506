import { IVideoParticipant } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

export function useVideoParticipants({
  waitForAuthentication = true,
  options,
}: Omit<IUseGuiderSWRParams<IVideoParticipant>, 'url'>) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IVideoParticipant>({
      waitForAuthentication,
      url: '/videoParticipants',
      options: {
        ...options,
      },
    });

  return {
    videoParticipants: data,
    reqVideoParticipants: request,
    revalidateVideoParticipants: revalidate,
    errorVideoParticipants: error,
    isLoadingVideoParticipants: isLoading,
    isValidatingVideoParticipants: isValidating,
    isErrorVideoParticipants: Boolean(error),
  };
}
