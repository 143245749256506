import { ISession } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

interface UseSessionParams extends Omit<IUseGuiderSWRParams<ISession>, 'url'> {}

export function useSessions({
  waitForAuthentication = true,
  params,
  options,
}: UseSessionParams) {
  const { data, request, revalidate, meta, error, isLoading, isValidating } =
    useGuiderSWR<ISession>({
      waitForAuthentication,
      url: '/sessions',
      params,
      options,
    });

  return {
    sessions: data,
    sessionsMeta: meta,
    reqSessions: request,
    sessionsRevalidate: revalidate,
    errorsSessions: error,
    isLoadingSessions: isLoading,
    isErrorSessions: Boolean(error),
    isValidatingSessions: isValidating,
  };
}
