import { IVideo } from '@guider-global/shared-types';
import {
  IUseGuiderSWRParams,
  useGuiderSWR,
  IPaginationParams,
} from '@guider-global/swr';

interface IUseVideoRequestParams extends IPaginationParams {}
interface IUseVideoParams
  extends Omit<
    IUseGuiderSWRParams<IVideo, Partial<IVideo>, IUseVideoRequestParams>,
    'url'
  > {
  sessionId: string | undefined;
}
export function useVideos({
  waitForAuthentication = true,
  options,
  sessionId,
  params,
}: IUseVideoParams) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IVideo, Partial<IVideo>, IUseVideoRequestParams>({
      waitForAuthentication,
      url: `/video/${sessionId}`,
      params: {
        ...params,
      },
      options: {
        ...options,
      },
    });

  return {
    video: data,

    reqVideo: request,
    revalidateVideo: revalidate,
    errorVideo: error,
    isLoadingVideo: isLoading,
    isValidatingVideo: isValidating,
    isErrorVideo: Boolean(error),
  };
}
