import { INote } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

export function useNotes({
  waitForAuthentication = true,
  options,
}: Omit<IUseGuiderSWRParams<INote>, 'url'>) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<INote>({
      waitForAuthentication,
      url: '/notes',
      options: {
        keepPreviousData: true,
        ...options,
      },
    });
  return {
    notes: data,
    reqNotes: request,
    revalidateNotes: revalidate,
    errorNotes: error,
    isLoadingNotes: isLoading,
    isValidatingNotes: isValidating,
    isErrorNotes: Boolean(error),
  };
}
