import { ISkill } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

export type UseSkillsOptions = Omit<IUseGuiderSWRParams<ISkill>, 'url'>;

export function useSkills({
  waitForAuthentication = true,
  options,
}: UseSkillsOptions) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<ISkill>({
      waitForAuthentication,
      url: '/skills',
      options: {
        keepPreviousData: true,
        ...options,
      },
    });

  return {
    skills: data,
    reqSkills: request,
    skillsRevalidate: revalidate,
    errorsSkills: error,
    isLoadingSkills: isLoading,
    isErrorSkills: Boolean(error),
    isValidatingSkills: isValidating,
  };
}
