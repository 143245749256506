import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { ICustomField } from '@guider-global/shared-types';
import customFieldsSlice from 'store/slices/customFieldsSlice';
import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useAuth } from '@guider-global/auth-hooks';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle CustomFields REST API requests connected the redux store
 * @deprecated use useCustomFields from @guider-global/front-end-hooks
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<ICustomField> hook
 * @returns ...hook - all return values via the `useRestRedux<ICustomField>()` hook
 * @returns customFields - array of customFields in the RestRootState reducer
 * @returns reqCustomFields - makes requests and stores results in the customFieldsReducer
 * @returns getErrorsCustomFields - gets API errors from the customFieldsReducer
 */
export function useCustomFields({
  waitForAuthentication = true,
  ...args
}: RestReduxHook<ICustomField, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<ICustomField, RestRootStateTypes> =
    useRestRedux<ICustomField, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'customFields',
      actions: customFieldsSlice.actions,
      waitForAuthentication,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    customFields: hook.getResults,
    reqCustomFields: hook.request,
    getErrorsCustomFields: hook.getErrors,
    hasResultsCustomFields: hook.hasResults,
    getMessageCustomFields: hook.getMessage,
    getCodeCustomFields: hook.getCode,
    isLoadingCustomFields: hook.isLoading,
    isErrorCustomFields: hook.isError,
    isSuccessCustomFields: hook.isSuccess,
  };
}
