import { IGoal } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

export function useGoals({
  waitForAuthentication = true,
  options,
}: Omit<IUseGuiderSWRParams<IGoal>, 'url'>) {
  const { data, request, revalidate, error, isLoading, isValidating } =
    useGuiderSWR<IGoal>({
      waitForAuthentication,
      url: '/goals',
      options: {
        keepPreviousData: true,
        ...options,
      },
    });

  return {
    goals: data,
    reqGoals: request,
    revalidateGoals: revalidate,
    errorGoals: error,
    isLoadingGoals: isLoading,
    isValidatingGoals: isValidating,
    isErrorGoals: Boolean(error),
  };
}
