import { IReview } from '@guider-global/shared-types';
import { IUseGuiderSWRParams, useGuiderSWR } from '@guider-global/swr';

interface UseReviewParams extends Omit<IUseGuiderSWRParams<IReview>, 'url'> {}

export function useReviews({
  waitForAuthentication = true,
  params,
}: UseReviewParams) {
  const { data, request, revalidate, meta, error, isLoading, isValidating } =
    useGuiderSWR<IReview>({
      waitForAuthentication,
      url: '/reviews',
      params,
    });

  return {
    reviews: data,
    reviewsMeta: meta,
    reqReviews: request,
    reviewsRevalidate: revalidate,
    errorsReviews: error,
    isLoadingReviews: isLoading,
    isErrorReviews: Boolean(error),
    isValidatingReviews: isValidating,
  };
}
