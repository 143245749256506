import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { TOTPSurvey } from '@guider-global/shared-types';

import { RestRootState, RestRootStateTypes, RootState } from 'store';

import { useDatadogContext } from '@guider-global/datadog';
import otpSurveysSlice from 'store/slices/otpSurveysSlice';

/**
 * A custom hook to handle OTPSurveys REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IOTPSurveys> hook
 * @returns ...hook - all return values via the `useRestRedux<IOTPSurveys>()` hook
 * @returns otpSurveys - array of otpSurveys in the RestRootState reducer
 * @returns reqOtpSurveys - makes requests and stores results in the otpSurveysReducer
 */
export function useOTPSurveys({
  ...args
}: RestReduxHook<TOTPSurvey, RestRootState, RootState>) {
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<TOTPSurvey, RestRootStateTypes> =
    useRestRedux<TOTPSurvey, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'otpSurveys',
      actions: otpSurveysSlice.actions,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    otpSurveys: hook.getResults,
    reqOTPSurveys: hook.request,
    getErrorsOTPSurveys: hook.getErrors,
    hasResultsOTPSurveys: hook.hasResults,
    getMessageOTPSurveys: hook.getMessage,
    getCodeOTPSurveys: hook.getCode,
    isOTPSurveys: hook.isLoading,
    isErrorOTPSurveys: hook.isError,
    isSuccessOTPSurveys: hook.isSuccess,
  };
}
